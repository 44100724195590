// import node-modules ----------------------------------------------------------
import { 
    Box, 
    // useTheme,
    useMediaQuery,
} from '@mui/material'
import React, { useRef, useEffect } from "react"
import { motion, useAnimation, useInView } from "framer-motion"

// import created modules -------------------------------------------------------
// import { TextMotionOnWords, TextMotionOnCharacters } from '../utils/motioncomponents/TextMotion'
// import DropLeftMotion from '../utils/motioncomponents/DropLeftMotion'
// import DropRightMotion from '../utils/motioncomponents/DropRightMotion'
// import FadeInMotion from '../utils/motioncomponents/FadeInMotion'

// import images ----------------------------------------------------------------
import { WhatIsGPRBgImageLg } from '../../assetsv1/Assets'

// usp-list
const uspList = [
    {
        index: "01",
        detail: "Ultra wide band antennas allow for software-defined live/post-facto frequency band selection"
    },
    {
        index: "02",
        detail: "Customised RF, antenna and form factor design for specific industrial use cases"
    },
    {
        index: "03",
        detail: "Easy-to-use data processing software that allows to apply any combination of image processing filters as needed"
    },
    {
        index: "04",
        detail: "Technical support from Sisir Radar’s technical team in interpreting results"
    },
    {
        index: "05",
        detail: "Optional GPS add-on and software to correlate subterranean map with geo-location"
    }
]

// create the USP layout --------------------------------------------------------
export default function USP(){
    // variables ----------------------------------------------------------------
    // mediaquery
    // const theme = useTheme()
    // const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    // const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToMobile = useMediaQuery('(max-width: 640px)')
    const isMatchToLaptopSm = useMediaQuery('(max-width: 1040px)')

    const ref = useRef(null)
    const isInView = useInView(ref, {once: true})
    const animationControls = useAnimation()

    // fire animation once if it is in view -------------------------------------
    useEffect(()=>{
        if(isInView){
            animationControls.start("visible")
        }
    }, [isInView])

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        width: '100vw',
        color: '#fff',
        fontFamily: 'prompt',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${WhatIsGPRBgImageLg})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexFlow: 'row wrap',
        maxWidth: isMatchToMobile? '252px' : isMatchToLaptopSm? '512px' : '772px',
        color: '#232C60',
        gap: '8px',
        mt: '40px',
        mb: '40px',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center'
    }
    const headingContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '16px',
        width: '220px',
        height: isMatchToMobile? 'fit-content' : '190px',
        backgroundColor: 'transparent',
        color: '#fff',
        fontFamily: 'Oxanium-Bold',
        fontSize: isMatchToMobile? '24px' : '28px',
        alignItems: 'center',
        justifyContent: 'center'
    }
    const cardContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '16px',
        width: '220px',
        height: isMatchToMobile? '140px' : '190px',
        backgroundColor: '#fff',
        color: '#232C60'
    }
    const child1ContainerStyle = {
        fontFamily: 'Oxanium-Bold',
        fontSize: isMatchToMobile? '18px' : '28px'
    }
    const child2ContainerStyle = {
        fontFamily: 'NunitoSans_7pt-Bold',
        fontSize: isMatchToMobile? '12px' : '14px'
    }

    // main layout --------------------------------------------------------------
    return (
        <>
            <motion.div 
                ref={ref}
                variants={{
                    hidden: {opacity: 0},
                    visible: {
                        opacity: 1,
                        transition: {
                            staggerChildren: 0.25,
                        }
                    }
                }}
                initial="hidden"
                animate={animationControls}
                style={flexMainContainerStyle}
            >
                <Box sx={flexSubContainerStyle}>
                    <Box sx={headingContainerStyle}>
                        USP
                    </Box>
                    {
                        uspList.map((item, idx)=>(
                            <motion.div 
                                key={idx} 
                                style={cardContainerStyle}
                                variants={{
                                    hidden: {opacity: 0},
                                    visible: {opacity: 1}
                                }}
                            >
                                <p style={child1ContainerStyle}>
                                    {item.index}
                                </p>
                                <p style={child2ContainerStyle}>
                                    {item.detail}
                                </p>
                            </motion.div>
                        ))
                    }
                </Box>
                
            </motion.div>
        </>
    )
}