// import node-modules ----------------------------------------------------------
import React from 'react'
import { BrowserRouter, Routes, Route} from 'react-router-dom'

// import created modules -------------------------------------------------------
import Home from './components/pages/Home'
import AboutUs from './components/pages/AboutUs'
import Technology from './components/pages/Technology'
import SAR from './components/pages/SAR'
import GPR from './components/pages/GPR'
import HySpec from './components/pages/HySpec'
import MainLayout from './components/mainlayout/MainLayout'
import Error from './components/pages/Error'

// create main website layout ---------------------------------------------------
export default function App() {
  
  // main layout ----------------------------------------------------------------
  return (
    <>
    <BrowserRouter basename='/'>
      <Routes>
        <Route path='/' element={<MainLayout />} >
          <Route index element={<Home/>}/>
          <Route path='aboutus' element={<AboutUs />} />
          <Route path='technology' element={<Technology />}>
            {/* <Route index element={<Technology/>}/>
            <Route path='sar-tech' element={<SAR />} />
            <Route path='gpr-tech' element={<GPR />} /> */}
          </Route>
          <Route path='technology/sar' element={<SAR />} />
          <Route path='technology/gpr' element={<GPR />} />
          <Route path='technology/hyspec' element={<HySpec />} />
        </Route>
        <Route path='*' element={<Error/>} />
      </Routes>
    </BrowserRouter>
    </>
  )
}