// import node-modules ----------------------------------------------------------
import {
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material'

// import created modules -------------------------------------------------------
// import FadeInMotion from '../utils/motioncomponents/FadeInMotion'

// import images ----------------------------------------------------------------
import {
    AdvisorDrRussellKiethRaney,
    AdvisorNeelakantanSivathanuPillai,
    AdvisorPadmaVibhushanGMadhavanNair,
    AdvisorProfDrIngAlbertoMoreira,
} from '../../assetsv1/Assets'

// import created modules -------------------------------------------------------
import CustomCard from './CustomCard'

// list of advisors -------------------------------------------------------------
const advisorsList = [
    {
        name: 'DR RUSSEL KIETH RANEY',
        designation: '',
        personImage: AdvisorDrRussellKiethRaney,
        mediaIcons: [],
        description: [
            {
                mainText: 'For over four decades, Dr Russell Keith Raney has been one of the most distinguished pioneers of Synthetic Aperture Radar (SAR) systems and techniques.',
                subText: [],
            },
            {
                mainText: 'Dr Keith Raney is widely known as the first proponent of Digital SAR processing when at the time all experts were of the view that optical processing was the only feasible way. He brought out the first Digital SAR processor under the aegis of Canada’s MDA for SEASAT SAR data (1978). Though the SEASAT processor used the classical Range Doppler algorithm, he soon understood the fine limitations of this algorithm and proposed the Chirp Scaling algorithm — together with colleagues from Germany and Canada — for simplifying and improving the process for retrieving images from the data collected by the radar.',
                subText: [],
            },
            {
                mainText: 'He was the inspiration behind the CCRS (Canadian Centre for Remote Sensing) radar remote sensing programs. He was the principal architect of the celebrated Radarsat-1 satellite. The Radarsat series — Radarsats 1 and 2, followed by the Radarsat Constellation Mission (RCM) — demonstrates that complex and costly SAR satellites can also be profitable and support a wide variety of SAR data applications from commercial utility to climate change assessment.',
                subText: [],
            },
            {
                mainText: 'In addition to this, he contributed to the design of Pioneer and Magellan, NASA’s Venus orbital radars. Magellan demonstrated that one can cobble up a great interplanetary mission from leftover hardware, enabled by new ideas such as Block Adaptive Quantisation. His SAR-mode approach is the backbone of the European Space Agency’s first Earth Explorer satellite Cryosat, a radar altimeter designed primarily to monitor changes in polar ice sheets. Cryosat’s SAR-mode altimetry has been adopted as the standard for international operational oceanic radar altimeter missions such as Freilich and Sentinel-3.',
                subText: [],
            },
            {
                mainText: 'Dr R K Raney has connections to Indian Space Industry. He was the architect of the Hybrid Polarimetric S band Mini SAR payload of India’s Chandrayaan-1. This instrument delivered evidence of water ice in the cold depths of permanently shadowed lunar craters. Before Mini SAR, there were very few scientists — countable on fingers — who believed that there is water at all on moon’s surface. After Mini SAR, the idea of water on the Moon has been accepted as reality.',
                subText: [],
            },
            {
                mainText: 'One of Dr Raney’s significant contributions is the path breaking idea of Hybrid Polarimetry, first flown in space aboard Chandrayaan-1 lunar mission (2008), which ISRO demonstrated for the first time in Earth orbit with RISAT-1 (2012). This successful mission proved that full polarimetry can be readily implemented in any SAR imaging mode, a major breakthrough. Conventional Earth observing SAR full polarimetry uses the Quad-pol method (since 1985). Quad pol’s unique mode yields impressive results, but is limited to low incidence angles, and suffers from poor area coverage. As a result, Quad-pol products were never adopted by operational agencies. In contrast, the Hybrid Polarimetry method has no such limitations, hence this innovative approach operationalizes orbital radar polarimetry. Though his idea was rejected by most SAR experts in its inception stages, Hybrid Polarimetry was incorporated in Canada’s RCM following RISAT-1’s precedent, and today is on the path to revolutionising polarimetric utility from orbital SAR systems.',
                subText: [],
            },
            {
                mainText: 'It is a matter of extreme honour for Sisir Radar to have Dr Russell Keith Raney as an advisor.',
                subText: [],
            }
        ],
    },
    {
        name: 'PADMA VIBHUSHAN G. MADHAVAN NAIR',
        designation: 'Former Chairman, ISRO',
        personImage: AdvisorPadmaVibhushanGMadhavanNair,
        mediaIcons: [],
        description: [
            {
                mainText: 'A visionary in the truest sense, G. Madhavan Nair’s expertise in rocket systems remains unparalleled. Under his leadership as the ISRO Chairman, the country saw the launch of its first lunar probe, Chandrayaan-1.',
                subText: [],
            },
            {
                mainText: 'As the Director of the largest space research centre of ISRO, Vikram Sarabhai Space Centre (VSSC), G. Madhavan Nair overlooked the development of the Geo-synchronous Satellite Launch Vehicle (GSLV), an expendable launch system that is capable of orbiting 2000 kg class of satellite into Geo Transfer Orbit.',
                subText: [],
            },
            {
                mainText: 'Throughout his career, G. Madhavan Nair has sought to achieve self-reliance in high technology areas and to bring the benefits of space technology to national development, specifically addressing the needs of rural and poor sections of the society.',
                subText: [],
            },
            {
                mainText: 'The Government of India has bestowed upon him Padma Bhushan in 1998 and Padma Vibhushan in 2009.',
                subText: [],
            },
            {
                mainText: 'Sisir Radar is honoured to have G. Madhavan Nair as an advisor.',
                subText: [],
            }
        ],
    },
    {
        name: 'PROF. DR.-ING. ALBERTO MOREIRA',
        designation: 'Director of the Microwaves And Radar Institute, German Aerospace Center (DLR)',
        personImage: AdvisorProfDrIngAlbertoMoreira,
        mediaIcons: [],
        description: [
            {
                mainText: 'A pioneer in radar technology, Prof. Moreira has been the Director of the Microwaves and Radar Institute at DLR and a full Professor with the Karlsruhe Institute of Technology, Germany, since 2001. He oversaw the upgrading of the DLR airborne SAR system so that it could operate in innovative imaging modes such as polarimetric SAR interferometry, SAR tomography and holography.',
                subText: [],
            },
            {
                mainText: 'With TanDEM-X, led by his Institute, a high-resolution, global digital elevation model (DEM) of the Earth has been created with unprecedented accuracy. A fellow of the IEEE, Prof. Moreira served as President of the IEEE Geoscience and Remote Sensing Society (GRSS) in 2010.',
                subText: [],
            },
            {
                mainText: 'We are honoured to have Prof. Dr.-Ing. Alberto Moreira as an advisor at Sisir Radar.',
                subText: [],
            }
        ],
    },
    {
        name: 'NEELAKANTAN SIVATHANU PILLAI',
        designation: 'Retired ISRO Scientist',
        personImage: AdvisorNeelakantanSivathanuPillai,
        mediaIcons: [],
        description: [
            {
                mainText: 'Neelakantan Sivathanu Pillai (N. S. Pillai) obtained his B. E. degree in Electrical Engineering from PSG College of Technology, University of Madras in 1967 and M.Tech degree in Microwaves and Communication Engineering from IIT Bombay in 1969. He was a member of the research staff in IIT Bombay and carried out research on varactor diode multiplayers.',
                subText: [],
            },
            {
                mainText: `He joined ISRO at Ahmedabad in 1971. He designed and developed the Cassegrain Antenna and high power conical scanning feed system for the tracking radar for ISRO's first satellite launch vehicle SLV 3 mission. He was deputed to France under ISRO CNES program in 1974 to work on the Spaceborne Synthetic Aperture Radar studies at Thomson CSF. After his return, he sphere headed the Microwave Remote Sensing Program and undertook studies related to that. He was the team leader for the development of the Airborne real aperture imaging X band radar. The first successful flight was conducted in 1980. He later led the development of the first C band airborne Synthetic Aperture Radar development in ISRO.`,
                subText: [],
            },
            {
                mainText: 'He also headed the various technology development and system studies activities related to the Spaceborne Synthetic Aperture Radar and Scatterometer and built up a strong system design and analysis team. A 1-18 Ghz truck mounted Scatterometer with polarisation and incidence angle diversity was also developed for signature studies under his leadership in the 80s.',
                subText: [],
            },
            {
                mainText: 'We are honoured to have him as an advisor at Sisir Radar.',
                subText: [],
            }
        ],
    },
]

// create the advisors section --------------------------------------------------
export default function Advisors(){

    // const theme & media-query ------------------------------------------------
    const theme = useTheme()
    const isMatchToSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')

    // style --------------------------------------------------------------------
    const flexMainContainerStyle = {
        display: 'flex',
        backgroundColor: '#232C60',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        font: 'prompt',
        wrap: 'wrap',
    }
    const child1Style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        fontSize: '2rem',
        fontStyle: 'bold'
    }
    const child2Style = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        width: '100%',
        flexFlow: 'wrap',
        gap: '1.5rem',
    }
    const flexSubContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '95%',
        m: isMatchToTablet? '72px 0px' : isMatchToLaptop? '82px 0px' : '96px 0px',
        gap: isMatchToTablet? '52px' : '48px',
    }
    const cardContainerStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexFlow: 'wrap',
        gap: '1.5rem',
    }

    // main layout --------------------------------------------------------------
    return(
        <>
        <Box sx={flexMainContainerStyle}>
            <Box sx={flexSubContainerStyle}>
                <Box sx={child1Style}>
                    <p 
                        style={{
                            fontFamily:'Oxanium-Bold', 
                            textTransform: 'uppercase', 
                            fontSize: isMatchToSmallDevice? '24px' : isMatchToTablet? '32px' : '40px'
                        }}
                    >
                        HONORARY advisors
                    </p>
                </Box>
                <Box sx={child2Style}>
                    <Box sx={cardContainerStyle}>
                        {
                            advisorsList.slice(0, 2).map((item, index)=>(
                                <CustomCard 
                                    key={index}
                                    name={item.name}
                                    personImage={item.personImage} 
                                    designation={item.designation}
                                    description={item.description}
                                />
                                // <FadeInMotion key={index} durationTime={0.5} delayTime={index * 0.5}>
                                //     <CustomCard 
                                //         name={item.name}
                                //         personImage={item.personImage} 
                                //         designation={item.designation}
                                //         description={item.description}
                                //     />
                                // </FadeInMotion>
                            ))
                        }
                    </Box>
                    <Box sx={cardContainerStyle}>
                        {
                            advisorsList.slice(2, 4).map((item, index)=>(
                                <CustomCard 
                                    key={index}
                                    name={item.name}
                                    personImage={item.personImage} 
                                    designation={item.designation}
                                    description={item.description}
                                />
                                // <FadeInMotion key={index} durationTime={0.5} delayTime={(index + 2) * 0.5}>
                                //     <CustomCard 
                                //         name={item.name}
                                //         personImage={item.personImage} 
                                //         designation={item.designation}
                                //         description={item.description}
                                //     />
                                // </FadeInMotion>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
        </>
    )
}