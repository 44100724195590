// import node modules ----------------------------------------------------------
import React from "react"

// import created modules -------------------------------------------------------

// import images ----------------------------------------------------------------
import { Page404DigitsImage } from "../../assetsv1/Assets"
// import css file --------------------------------------------------------------
import "./PageNotFound.css"

// create error page layout -----------------------------------------------------
export default function PageNotFound(){
    // variables ----------------------------------------------------------------

    // main layout --------------------------------------------------------------
    return(
        <>
            <div id="error-page-root">
                <div id="error-status">
                    <img src={Page404DigitsImage} alt="404"/>
                </div>
                <div id="error-message">
                    <p id="page-does-not-exist-message">
                        Oops! it loooks like the page you are looking for is not here.
                    </p>
                    <p id="redirecting-to-home-page-messsage">
                        Feel free to explore our homepage. We appreciate your time and hope you find what you are looking for! 
                    </p>
                    <a id="home-page-link-button" href="https://www.sisirradar.com/">
                        GO TO HOMEPAGE
                    </a>
                </div>
            </div>
        </>
    )
}