//  import node modules ---------------------------------------------------------
import {React, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { 
    AppBar, 
    Toolbar,
    List, 
    ListItemButton, 
    IconButton,
    useMediaQuery,
    Drawer,
    Box,
} from '@mui/material'
// import { motion } from 'framer-motion'
import MenuIcon from '@mui/icons-material/Menu'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

// import created modules -------------------------------------------------------
import DropInMotion from '../utils/motioncomponents/DropInMotion'

// import images ----------------------------------------------------------------
import { SisirLogo, SisirLogoWhite } from '../../assetsv1/Assets'
import '../../index.css'

// create a list ----------------------------------------------------------------
const pageList = [
    {
        title: 'HOME',
        pageName: '/',
        description: 'shows the home page',
    },
    {
        title: 'ABOUT US',
        pageName: '/aboutus',
        description: 'shows the about-us page',
    },
    {
        title: 'TECHNOLOGY SOLUTIONS',
        pageName: '/technology',
        description: 'shows the technology page',
    },
    {
        title: 'CAREERS',
        pageName: '/careers',
        description: 'shows the career page',
    },

]

// url --------------------------------------------------------------------------
const careerLink = 'https://docs.google.com/forms/d/14isc_21pmB3yKuxonQ5R6Co09q1LVg8C2ip6mW4h2r0/viewform?edit_requested=true'
const contactLink = 'https://docs.google.com/forms/d/14isc_21pmB3yKuxonQ5R6Co09q1LVg8C2ip6mW4h2r0/viewform?edit_requested=true'

// function to get the vaertical scrolling zero ---------------------------------
const scrollToTop =() => {
  window.scrollTo(0,0)
}

// create a drawer component ----------------------------------------------------
const DrawerComp = ({scrolledY, setScrolledY, isMatchToMobile}) => {

    // mediaquery ---------------------------------------------------------------
    const isMatchToMdTablet = useMediaQuery('(max-width: 640px)')

    // state variables ----------------------------------------------------------
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openTechPageList, setOpenTechPageList] = useState(false)

    // navigate-hook ------------------------------------------------------------
    const navigate = useNavigate()

    // location hook ------------------------------------------------------------
    // const location = useLocation()

    // functions ----------------------------------------------------------------
    const handleClick=(item)=>{
      navigate(item.pageName)
      scrollToTop()
      setOpenDrawer(false)
      setOpenTechPageList(false)
    }
    // const handleCareerClick = () => {
    //   setOpenDrawer(false)
    //   setOpenTechPageList(false)
    //   navigate('/')
    //   scrollToTop()
    // }
    const handleContactClick = () => {
      setOpenDrawer(false)
      setOpenTechPageList(false)
      navigate('/')
      scrollToTop()
    }
    const handlePageRefresh = () => {
      window.scrollTo(0, 0)
    }
    window.addEventListener('beforeunload', handlePageRefresh)

    // drawer layout ------------------------------------------------------------
    return (
      <>
        <Drawer
          anchor="right"
          sx={{
            fontSize: '10px',
            backdropFilter: 'blur',
            '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                backgroundColor: 'rgba(35, 44, 96, 0.5)',
                width: isMatchToMobile? '60vw' : isMatchToMdTablet? '50vw' : '40vw'
            },
          }}
          open={openDrawer}
          onClose={() => {setOpenDrawer(false); setOpenTechPageList(false)}}
        >
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <IconButton 
              onClick={()=>{setOpenDrawer(false); setOpenTechPageList(false)}} 
              size="large"
              sx={{float: 'right', color: '#fff', ml: 'auto'}}
            >
              <CloseOutlinedIcon/>
            </IconButton>
          </Box>
          <List sx={{ml: 1, mr: 1, gap: '4px'}}>
            {pageList.slice(0, 2).map((item, index) => (
              <ListItemButton 
                sx={{
                  display: 'flex',
                  gap: '16px',
                  color: '#fff', 
                  fontFamily: 'Oxanium-Regular',
                  fontSize: '16px'
                }}
                key={index} 
                onClick={()=>{handleClick(item)}}
              >
                {item.title}
              </ListItemButton>
            ))}
            <ListItemButton 
              sx={{
                display: 'flex',
                gap: '16px',
                color: '#fff', 
                fontFamily: 'Oxanium-Regular',
                fontSize: '16px'
              }}
              // onClick={()=>{handleClick(pageList[2])}}
            >
              <Box
                onClick={()=>{handleClick(pageList[2])}}
              >
                {pageList[2].title}
              </Box>
              <div 
                style={{
                  transform: openTechPageList ? 'rotate(0deg)' : 'rotate(-90deg)',
                  transition: '300ms ease-in-out all'
                }}
                onClick={()=>{setOpenTechPageList(!openTechPageList)}}
              >
                <ArrowDropDownOutlinedIcon color="#232C60" fontSize={isMatchToMobile? 'medium' : 'large'}/>
              </div>
            </ListItemButton>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                height: openTechPageList ? '135px' : '0px',
                transition: '300ms ease-in-out all',
                opacity: openTechPageList? '1' : '0',
                marginBottom: openTechPageList? '20px' : '0px'
              }}
            >
              {
                [
                  {title: "SYNTHETIC APERTURE RADAR", pageName: '/technology/sar'}, 
                  {title: "GROUND PENETRATING RADAR", pageName: '/technology/gpr'}, 
                  {title: "HYPERSPECTRAL IMAGING", pageName: '/technology/hyspec'},
                  {title: "ALL PRODUCTS", pageName: '/technology'}
                ].map((item, index)=>(
                  <ListItemButton 
                    sx={{
                      // display: openTechPageList ? 'block' : 'none',
                      color: '#fff', 
                      fontFamily: 'Oxanium-Regular',
                      fontSize: '12px',
                      transition: '300ms ease-in-out all',
                    }}
                    key={index} 
                    onClick={()=>{handleClick(item)}}
                  >
                    {item.title}
                  </ListItemButton>
                ))
              }
            </div>
            {/* <a 
              href={careerLink} 
              rel='noreferrer'
              target='_blank'
              style={{textDecoration: 'none'}}
              onClick={()=>handleCareerClick()}
            >
              <ListItemButton 
                sx={{
                  color: '#fff', 
                  fontFamily: 'Oxanium-Regular',
                  fontSize: '16px'
                }}
              >
                {pageList[3].title}
              </ListItemButton>
            </a> */}
            <a 
              href={contactLink} 
              rel='noreferrer'
              target='_blank'
              style={{textDecoration: 'none'}}
              onClick={()=>handleContactClick()}
            >
              <ListItemButton 
                sx={{
                  color: '#fff', 
                  fontFamily: 'Oxanium-Regular',
                  fontSize: '16px'
                }}
              >
                
                    CONTACT NOW
              </ListItemButton>
            </a>
          </List>
        </Drawer>
        <IconButton
          sx={{ 
            // color: (!openDrawer)?((location.pathname !== '/' || scrolledY)? '#232C60' : '#fff'): 'transparent', 
            color: !openDrawer ? (scrolledY ? '#232C60' : '#fff'): 'transparent',
            marginLeft: "auto" ,
            transition: 'color 1s ease'
          }}
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon color="#232C60" fontSize={isMatchToMobile? 'medium' : 'large'}/>
        </IconButton>
      </>
    )
  }

// create a Header that will appear on every page -------------------------------
export default function Header(){
  
    // const theme & media-query ------------------------------------------------
    const isMatch = useMediaQuery('(max-width: 1024px)')
    const isMatchToMobile = useMediaQuery('(max-width: 480px)')
    const isMatchToTablet = useMediaQuery('(max-width: 960px)')
    const isMatchToLaptop = useMediaQuery('(max-width: 1600px)')
    const isMatchToDesktop = useMediaQuery('(max-width: 1920px)')
    // const isMatchToLgDesktop = useMediaQuery('(min-width: 1921px)')
    const isHoverEnable = useMediaQuery('(hover: hover)')

    // state variables ----------------------------------------------------------
    const [hovered, setHovered] = useState(false)
    const [scrolledY, setScrolledY] = useState(false)
    const [activePage, setActivePage] = useState()
    const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false)

    // navigate-hook ------------------------------------------------------------
    const navigate = useNavigate()

    // use-location hook ------------------------------------------------------------
    const location = useLocation()
 
    // functions ---------------------------------------------------------------- 
    const handleChangeColor = () => {
      if(window.scrollY > 0){
        setScrolledY(true)
      }
      else{
        setScrolledY(false)
      }
    }
    window.addEventListener('scroll', handleChangeColor)

    const handlePageRefresh = () => {
      window.scrollTo(0, 0)
    }
    window.addEventListener('beforeunload', handlePageRefresh)

    // handle career-tab-click
    // const handleCareerClick = (url) => {
    //   window.open(url)
    //   navigate('/')
    //   scrollToTop()
    // }
    const handleContactClick = (url) => {
      window.open(url)
      navigate('/')
      scrollToTop()
    }

    // style --------------------------------------------------------------------
    const appbarStyle = {
        // background: (location.pathname !== '/')? '#fff' : scrolledY? '#fff' : 'transparent',
        backgroundColor: scrolledY? '#fff' : 'transparent',
        boxShadow: '0',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: isMatchToMobile? '0px' : isMatchToTablet? '8px' : isMatchToLaptop? '24px' : '56px',
        paddingRight: isMatchToMobile? '0px' : isMatchToTablet? '8px' : isMatchToLaptop? '24px' : '56px',
        mt: 0,
        transition: 'background 1s ease'
    }
    const toolbarStyle = {
      gap: '24px',
    }
    // main layout --------------------------------------------------------------
    return(
        <>
            <AppBar sx={appbarStyle}>
                <Toolbar sx={toolbarStyle}>

                  {/* used drop in motion to create the motion from top to bottom */}
                  <DropInMotion>
                    <img 
                      src={scrolledY? SisirLogo : SisirLogoWhite} 
                      alt='Sisir Radar' 
                      style={{
                        width: isMatchToMobile? '64px' : isMatchToTablet? '103px' : isMatchToLaptop? '103px' : isMatchToDesktop? '103px' : '128px',
                        // width : isMatchToMobile?'16vw': '6vw',
                        cursor: 'pointer'
                      }}
                      onClick={()=> {navigate('/'); scrollToTop()}} 
                    />
                  </DropInMotion>

                    {/* -------------not used in version-1------------------- */}
                    {/* <img 
                      src={MakeInIndiaLogo} 
                      alt='Make in India' 
                      style={{
                        // width: isMatchToMobile? '52px' : isMatchToTablet? '88px' : isMatchToLaptop? '88px' : isMatchToDesktop? '88px' : '96px'
                        width : isMatchToMobile?'15vw':'5vw'
                      }}
                    /> */}
                    {/* ----------------------------------------------------- */}

                    {
                        (isMatch) ? ( 
                        <>
                        <DrawerComp 
                          scrolledY={scrolledY} setScrolledY={setScrolledY}
                          isMatchToMobile={isMatchToMobile}
                        />
                        </>
                        ) : (
                            <>
                                <Box 
                                    sx={{
                                      position: 'relative',
                                      marginLeft: 'auto', 
                                      // fontFamily: 'Prompt', 
                                      fontFamily: 'Oxanium-Bold',
                                      fontWeight: 600,
                                      display: 'flex',
                                      gap: '32px',
                                      alignItems: 'center',
                                      cursor: 'default',
                                      // color: (location.pathname !== '/' || scrolledY)? '#232C60' : '#fff',
                                      color: scrolledY? '#232C60' : '#fff',
                                      fontSize: '16px',
                                      letterSpacing: '1.5px'
                                    }}
                                >
                                    {
                                        pageList.slice(0, 2).map((item, index)=>(
                                            <DropInMotion key={index}>
                                                <Box 
                                                    // key={index}
                                                    sx={{
                                                      textDecoration: location.pathname === item.pageName? 'underline' : activePage === item.pageName ? 'underline' : 'none',
                                                      textUnderlineOffset: '10px',
                                                      textDecorationThickness: '1.5px',
                                                      cursor: 'pointer'
                                                    }}
                                                    onClick={()=>{
                                                    navigate(item.pageName)
                                                    scrollToTop()
                                                    }}
                                                    onMouseEnter={()=>{
                                                    setActivePage(item.pageName)
                                                    }}
                                                    onMouseLeave={()=>{
                                                    location.pathname === item.pageName ? setActivePage(item.pageName) : setActivePage('')
                                                    }}
                                                >
                                                  {item.title}
                                                </Box>
                                            </DropInMotion>
                                        ))
                                    }
                                    <DropInMotion>
                                      <Box  className='tech-item-container' sx={{cursor: 'pointer'}}>
                                      <Box 
                                          // className='tech-item-container'
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                            // position: 'relative',
                                            // textDecoration: location.pathname === pageList[2].pageName? 'underline' : activePage === pageList[2].pageName ? 'underline' : 'none',
                                            // textUnderlineOffset: '10px',
                                            // textDecorationThickness: '1.5px'
                                          }}
                                          // onClick={()=>{
                                          //   navigate(pageList[2].pageName)
                                          //   scrollToTop()
                                          // }}
                                          onMouseEnter={()=>{
                                            setActivePage(pageList[2].pageName)}}
                                          onMouseLeave={()=>{
                                            location.pathname === pageList[2].pageName ? setActivePage(pageList[2].pageName) : setActivePage('')
                                          }}
                                      >
                                        <div 
                                          onClick={()=>{
                                            navigate(pageList[2].pageName)
                                            scrollToTop()
                                          }}
                                        >
                                          {pageList[2].title}
                                        </div>
                                        <div 
                                          className='drop-down-icon' 
                                          style={{
                                            transform: isHoverEnable ? '' : (dropDownMenuOpen ? 'rotate(0deg)' : 'rotate(-90deg)'),
                                            transition: '300ms ease-in-out all',
                                            width: '32px'
                                          }}
                                          onMouseEnter={()=> {setDropDownMenuOpen(true)}}
                                          onClick={()=>{setDropDownMenuOpen(true)}}
                                        >
                                          <ArrowDropDownOutlinedIcon color="#232C60" fontSize={isMatchToMobile? 'medium' : 'large'}/>
                                        </div>
                                      </Box>
                                      <div 
                                        className='tech-dropdown-menu' 
                                        style={{
                                          // display:(dropDownMenuOpen ? 'flex' : 'none'),
                                          // opacity: (dropDownMenuOpen ? '1' : '0'),
                                          // height: (dropDownMenuOpen ? '141px' : '0px')
                                          display: isHoverEnable ? '' : (dropDownMenuOpen ? 'flex' : 'none'),
                                          opacity: isHoverEnable ? '' : (dropDownMenuOpen ? '1' : '0'),
                                          // height: isHoverEnable ? '' : (dropDownMenuOpen ? '141px' : '0px')
                                        }}
                                        // onMouseEnter={()=> {setDropDownMenuOpen(true)}}
                                        onMouseLeave={()=> {setDropDownMenuOpen(false)}}
                                      >
                                        <div className='tech-dropdown-item' id='sar' onClick={()=>{navigate('/technology/sar'); scrollToTop(); setDropDownMenuOpen(false)}}>
                                          Synthetic aperture radar
                                        </div>
                                        <div className='tech-dropdown-item' id='sar' onClick={()=>{navigate('/technology/gpr'); scrollToTop(); setDropDownMenuOpen(false)}}>
                                          Ground Penetrating Radar
                                        </div>
                                        <div className='tech-dropdown-item' id='sar' onClick={()=>{navigate('/technology/hyspec'); scrollToTop(); setDropDownMenuOpen(false)}}>
                                          Hyperspectral Imaging
                                        </div>
                                        <div className='tech-dropdown-item' id='tech-sol' onClick={()=>{navigate('/technology'); scrollToTop(); setDropDownMenuOpen(false)}}>
                                          All Products
                                        </div>
                                      </div>
                                      </Box>
                                    </DropInMotion>
                                    {/* <DropInMotion>
                                      <Box 
                                        onClick={()=>handleCareerClick(careerLink)}
                                        onMouseEnter={(event)=>{
                                          event.target.style.textDecoration = 'none'
                                          event.target.style.textUnderlineOffset = '10px'
                                          event.target.style.textDecorationThickness = '1.5px'
                                        }}
                                        onMouseLeave={(event)=>{
                                          event.target.style.textDecoration = 'none'
                                        }}
                                      >
                                        {pageList[3].title}
                                      </Box>
                                    </DropInMotion> */}
                                    <DropInMotion>
                                      <a 
                                        href = {careerLink} 
                                        rel='noreferrer'
                                        target='_blank'
                                        style={{textDecoration: 'none'}}
                                      >
                                        <Box  
                                          sx={{
                                            // backgroundColor: (hovered)? 'transparent' : '#232C60',
                                            background: hovered ? (scrolledY? '#232C60' :  '#fff') : 'transparent', 
                                            // color: (location.pathname === '/') ? ((hovered && scrolledY)?'#232C60' : '#fff') : (hovered? '#232C60' : '#fff'),
                                            color: scrolledY ? (hovered? '#fff' : '#232C60') : (hovered ? '#232C60' : '#fff'),
                                            // ml: 1,
                                            // border:(location.pathname === '/') ? ((hovered && !scrolledY)? 'solid #fff' : 'solid #232C60') : 'solid #232C60' ,
                                            border: scrolledY ? 'solid #232C60' : 'solid #fff',
                                            fontSize: 'clamp(18px, 1vw, 20px)',
                                            fontWeight: 'bold',
                                            padding: '8px 18px',
                                            alignSelf: 'center',
                                            // fontFamily: 'PromptBold1',
                                            fontFamily: 'Oxanium-ExtraBold'
                                          }}
                                            onMouseEnter={(()=>setHovered(true))}
                                            onMouseLeave={()=>setHovered(false)}
                                            onClick={()=>handleContactClick(contactLink)}
                                        >
                                          CONTACT NOW
                                        </Box>
                                      </a>
                                    </DropInMotion>
                                </Box>
                            </>
                        )
                    }
                </Toolbar>
                {/* {activePage == pageList[2].pageName && 
                  <>
                    <motion.div style={dropdownManuStyle}>
                      <li 
                        onMouseEnter={(event)=>{
                          event.target.style.backgroundColor = '#ccc'
                        }}
                        onMouseLeave={(event)=>{
                          event.target.style.backgroundColor = '#fff'
                        }}
                        onClick={()=>navigate('/technology/sar')}
                      >
                        SAR
                      </li>
                      <li
                        onMouseEnter={(event)=>{
                          event.target.style.backgroundColor = '#ccc'
                        }}
                        onMouseLeave={(event)=>{
                          event.target.style.backgroundColor = '#fff'
                        }}
                        onClick={()=>navigate('/technology/gpr')}
                      >
                        GPR
                      </li>
                      <li
                        onMouseEnter={(event)=>{
                          event.target.style.backgroundColor = '#ccc'
                        }}
                        onMouseLeave={(event)=>{
                          event.target.style.backgroundColor = '#fff'
                        }}
                      >
                        Hyperspectral
                      </li>
                    </motion.div>
                  </>
                } */}
            </AppBar>
        </>
    )
}